import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout";

const NotFoundPage = () => {
  return (
      <Layout>
          <div className="text-center text-gray-200">
              <h1 className="text-3xl">Page not found</h1>
              <Link to="/">Go home</Link>
          </div>
      </Layout>
  )
}

export default NotFoundPage
